// ============================================================================
// WorkspaceApi
// -------------------
// Workspace api
// ============================================================================

// -------
// Imports
// -------
import { Backend } from '@/plugins/axios/Configurator'
import URLS from '@/apis/BackEndAPIEndpoints'

import { RequiredArguments } from '@/helpers/methods'

// -------
// Exports
// -------
export default {
    holding: {
        accountingFirm: {
            catalogTreeStructure: {
                read: function (holdingId, accountingFirmId, params = null, data = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                    RequiredArguments({ holdingId, accountingFirmId })
                    return Backend.GET(URLS.api.holdings.accountingFirms.catalogTreeStructures.uri, [holdingId, accountingFirmId], params, data, doCancelPreviousRequest, doCancelAllOtherRequests)
                }
            },
            vendors: {
                catalogTreeStructures: {
                    read: function (holdingId, accountingFirmId, params = null, data = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                        RequiredArguments({ holdingId, accountingFirmId })
                        return Backend.GET(URLS.api.holdings.accountingFirms.vendors.catalogTreeStructures.uri, [holdingId, accountingFirmId], params, data, doCancelPreviousRequest, doCancelAllOtherRequests)
                    }
                }
            },
            whiteLabel: {
                modify: function (holdingId, accountingFirmId, whiteLabelTheme, params = null, data = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                    RequiredArguments({ holdingId, accountingFirmId, whiteLabelTheme })
                    data.append('_method', 'PATCH')
                    return Backend.POST(URLS.api.holdings.accountingFirms.whiteLabels.uri, [holdingId, accountingFirmId, whiteLabelTheme], params, data, doCancelPreviousRequest, doCancelAllOtherRequests)
                }
            },
            whiteLabels: {
                read: function (holdingId, accountingFirmId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                    RequiredArguments({ holdingId, accountingFirmId })
                    return Backend.GET(URLS.api.holdings.accountingFirms.whiteLabels.uri, [holdingId, accountingFirmId], params, doCancelPreviousRequest, doCancelAllOtherRequests)
                }
            },
            create: function (holdingId, accountingFirmId, params = null, data = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                RequiredArguments({ holdingId })
                return Backend.POST(URLS.api.holdings.accountingFirms.uri, [ holdingId, accountingFirmId ], params, data, doCancelPreviousRequest, doCancelAllOtherRequests)
            },
            read: function (holdingId, accountingFirmId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                RequiredArguments({ holdingId, accountingFirmId })
                return Backend.GET(URLS.api.holdings.accountingFirms.uri, [holdingId, accountingFirmId], params, doCancelPreviousRequest, doCancelAllOtherRequests)
            },
            modify: function (holdingId, accountingFirmId, params = null, data = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                RequiredArguments({ holdingId, accountingFirmId })
                return Backend.PATCH(URLS.api.holdings.accountingFirms.uri, [ holdingId, accountingFirmId ], data, params, doCancelPreviousRequest, doCancelAllOtherRequests)
            },
            delete: function (holdingId, accountingFirmId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                RequiredArguments({ holdingId, accountingFirmId })
                return Backend.DELETE(URLS.api.holdings.accountingFirms.uri, [holdingId, accountingFirmId], params, doCancelPreviousRequest, doCancelAllOtherRequests)
            }
        },
        accountingFirms: {
            read: function (holdingId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                RequiredArguments({ holdingId })
                return Backend.GET(URLS.api.holdings.accountingFirms.uri, [holdingId], params, doCancelPreviousRequest, doCancelAllOtherRequests)
            }
        },
        domains: {
            read: function (holdingId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                RequiredArguments({ holdingId })
                return Backend.GET(URLS.api.holdings.domains.uri, [holdingId], params, doCancelPreviousRequest, doCancelAllOtherRequests)
            }
        }
    }
}
