<template>
	<w-navigation-drawer
		:absolute="$vuetify.breakpoint.smAndUp"
		default-width="600px"
		:fixed="$vuetify.breakpoint.xsOnly"
		max-width="100vw"
		:resizable="$vuetify.breakpoint.smAndUp"
		right
		:value="true"
		:width.sync="drawerWidth"
		@input="$event ? () => {} : closeDrawer()"
	>
		<v-layout column fill-height style="position: relative">
			<v-layout v-if="loading" align-center class="w-loading-container" justify-center style="height: 100%; position: absolute; width: 100%; z-index: 1">
				<v-progress-circular color="primary" indeterminate size="46" />
			</v-layout>
			<v-flex shrink>
				<v-toolbar color="primary" dark flat height="78px">
					<w-layout align-center row>
						<w-flex v-if="!value" text-truncate title>{{ $t('workspaces.create_a_workspace') }}</w-flex>
						<w-flex v-else text-truncate title>{{
							$t('workspaces.modify_a_workspace', { name: accountingFirm && accountingFirm.name ? accountingFirm.name : '' })
						}}</w-flex>
						<v-spacer />
						<w-flex shrink>
							<w-btn color="white" flat icon="close" mini @click="closeDrawer()">{{ $t('actions.close') }}</w-btn>
						</w-flex>
					</w-layout>
				</v-toolbar>
			</v-flex>
			<v-flex scroll-y>
				<AccountingFirmForm
					v-model="accountingFirmCopy"
					:readonly="(accountingFirm && !!accountingFirm.deleted_at) || archivingLoading || deletionLoading"
					:validation.sync="isFormValid"
					:width="drawerWidth"
				/>
			</v-flex>
			<v-flex shrink text-xs-right>
				<w-btn
					v-if="accountingFirm && !!accountingFirm.deleted_at"
					:disabled="reactivationLoading"
					color="error"
					:loading="deletionLoading"
					@click="deleteAccountingFirm()"
					>{{ $t('actions.definitly_delete') }}</w-btn
				>
				<w-btn
					v-if="accountingFirm && accountingFirm.id && !accountingFirm.deleted_at"
					color="error"
					:loading="archivingLoading"
					@click="archiveAccountingFirm()"
					>{{ $t('actions.deactivate') }}</w-btn
				>
				<w-btn
					v-if="accountingFirm && !!accountingFirm.deleted_at"
					:disabled="deletionLoading"
					flat
					:loading="reactivationLoading"
					@click="reactivateAccountingFirm()"
					>{{ $t('actions.reactivate') }}</w-btn
				>
				<w-btn-save
					v-if="accountingFirm && !accountingFirm.deleted_at"
					:disabled="!isFormValid || !isDirty || archivingLoading"
					:loading="formLoading"
					@click="saveAccountingFirm()"
				/>
			</v-flex>
		</v-layout>
	</w-navigation-drawer>
</template>

<script>
import WorkspaceModuleGuard from '@/mixins/ModulesGuards/Workspaces/WorkspaceModuleGuard'

const BASE_WHITELABEL = [
	{
		primary_color: '#2DC092',
		secondary_color: '#21375C',
		theme: 'light'
	},
	{
		primary_color: '#2DC092',
		secondary_color: '#21375C',
		theme: 'dark'
	}
]

export default {
	name: 'WorkspaceDrawer',
	components: {
		AccountingFirmForm: () => ({
			component: import('@/components/Workspaces/AccountingFirmForm')
		})
	},
	mixins: [WorkspaceModuleGuard],
	inject: ['holdingContext'],
	props: {
		value: {
			default: null,
			required: false,
			type: Number
		}
	},
	data: function () {
		return {
			accountingFirm: null,
			accountingFirmCopy: null,
			archivingLoading: false,
			deletionLoading: false,
			drawerWidth: 600,
			formLoading: false,
			isFormValid: false,
			loading: false,
			reactivationLoading: false
		}
	},
	computed: {
		dirtyAccountingFirmData: function () {
			const result = {
				white_labels: []
			}

			if (this.accountingFirm?.domain != this.accountingFirmCopy?.domain) {
				result.domain = this.accountingFirmCopy?.domain
			}
			if (this.accountingFirm?.name != this.accountingFirmCopy?.name) {
				result.name = this.accountingFirmCopy?.name
			}
			if (this.accountingFirm?.welcome_message != this.accountingFirmCopy?.welcome_message) {
				result.welcome_message = this.accountingFirmCopy?.welcome_message
			}

			const darkTheme = this.getDirtyWhiteLabelData('dark')
			const lightTheme = this.getDirtyWhiteLabelData('light')

			if (darkTheme) {
				result.white_labels.push(darkTheme)
			}
			if (lightTheme) {
				result.white_labels.push(lightTheme)
			}

			if (result.white_labels == 0) {
				delete result.white_labels
			}

			return result
		},
		holdingId: function () {
			return this.holdingContext?.holding_id
		},
		isAccountingFirmDirty: function () {
			return (
				this.accountingFirm?.domain != this.accountingFirmCopy?.domain ||
				this.accountingFirm?.name != this.accountingFirmCopy?.name ||
				this.accountingFirm?.welcome_message != this.accountingFirmCopy?.welcome_message
			)
		},
		isDirty: function () {
			return Object.keys(this.dirtyAccountingFirmData).length > 0
		}
	},
	watch: {
		holdingId: {
			handler: 'loadContext'
		},
		value: {
			handler: 'loadContext'
		}
	},
	mounted: function () {
		this.loadContext()
	},
	methods: {
		archiveAccountingFirm: function () {
			this.archivingLoading = true
			this.$dialog
				.warning({
					text: this.$t('workspaces.dialogs.archive_text', {
						name: this.accountingFirm.name
					}),
					title: this.$t('workspaces.dialogs.archive_title'),
					actions: {
						false: this.$t('actions.no'),
						true: this.$t('actions.yes')
					}
				})
				.then(choice => {
					if (!choice) {
						return
					}

					return this.service.archiveAccountingFirm(this.holdingId, this.accountingFirm.id).then(accountingFirm => {
						this.appEventBus.emit(this.appEvents.ACCOUNTING_FIRM_DELETED, accountingFirm)
						this.eventBus.emit(this.events.ACCOUNTING_FIRM_ARCHIVED, accountingFirm)
						this.accountingFirm.deleted_at = accountingFirm.deleted_at
						this.accountingFirmCopy.deleted_at = accountingFirm.deleted_at
						this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('workspaces.workspace_deactivated'))
					})
				})
				.finally(() => {
					this.archivingLoading = false
				})
		},
		closeDrawer: function (replace = false) {
			this.appService.goTo({
				name: 'workspaces',
				replace
			})
		},
		createAccountingFirm: function () {
			this.formLoading = true
			const accountingFirmData = {
				domain: this.accountingFirmCopy.domain,
				name: this.accountingFirmCopy.name,
				welcome_message: this.accountingFirmCopy.welcome_message
			}

			return this.service
				.createAccountingFirm(this.holdingId, accountingFirmData)
				.then(accountingFirm => {
					if (!this.service.hasModule('customization')) {
						return accountingFirm
					}
					const promises = []

					const whiteLabels = this.accountingFirmCopy.white_labels
					whiteLabels.forEach(whiteLabel => {
						promises.push(this.service.modifyWhiteLabel(this.holdingId, accountingFirm.id, whiteLabel.theme, whiteLabel))
					})

					return Promise.all(promises)
						.then(() => accountingFirm)
						.catch(error => {
							return this.service
								.archiveAccountingFirm(this.holdingId, accountingFirm.id)
								.then(() => {
									return this.service.deleteAccountingFirm(this.holdingId, accountingFirm.id, { delete_vendors: true })
								})
								.finally(() => {
									throw error
								})
						})
				})
				.then(accountingFirm => {
					this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('workspaces.workspace_created'))
					this.appEventBus.emit(this.appEvents.ACCOUNTING_FIRM_CREATED, accountingFirm)
					this.eventBus.emit(this.events.ACCOUNTING_FIRM_CREATED, accountingFirm)
					this.appService.goTo({
						name: 'workspaces-workspace',
						params: {
							workspaceId: accountingFirm.id
						}
					})
				})
				.finally(() => {
					this.formLoading = false
				})
		},
		deleteAccountingFirm: function () {
			this.deletionLoading = true
			this.$dialog
				.warning({
					text: this.$t('workspaces.dialogs.delete_text', {
						name: this.accountingFirm.name
					}),
					title: this.$t('workspaces.dialogs.delete_title'),
					actions: {
						false: this.$t('actions.no'),
						true: this.$t('actions.yes')
					}
				})
				.then(choice => {
					if (!choice) {
						return
					}

					return this.service
						.deleteAccountingFirm(this.holdingId, this.accountingFirm.id, {
							delete_vendors: true
						})
						.then(() => {
							this.eventBus.emit(this.events.ACCOUNTING_FIRM_DELETED, this.accountingFirm)
							this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('workspaces.workspace_deleted'))
							this.closeDrawer(true)
						})
				})
				.finally(() => {
					this.deletionLoading = false
				})
		},
		getDirtyWhiteLabelData: function (theme) {
			let currentWhiteLabel = {}
			let originalWhiteLabel = {}

			if (this.accountingFirmCopy?.white_labels) {
				currentWhiteLabel = this.accountingFirmCopy.white_labels.find(whiteLabel => whiteLabel.theme == theme)
			}
			if (this.accountingFirm?.white_labels) {
				originalWhiteLabel = this.accountingFirm.white_labels.find(whiteLabel => whiteLabel.theme == theme)
			}

			const result = {}

			if (currentWhiteLabel?.logo != originalWhiteLabel?.logo) {
				result.logo = currentWhiteLabel.logo
			}
			if (currentWhiteLabel?.primary_color != originalWhiteLabel?.primary_color) {
				result.primary_color = currentWhiteLabel.primary_color
			}
			if (currentWhiteLabel?.secondary_color != originalWhiteLabel?.secondary_color) {
				result.secondary_color = currentWhiteLabel.secondary_color
			}

			return result
		},
		isWhiteLabelDirty: function (theme) {
			const whiteLabel = this.getDirtyWhiteLabelData(theme)

			return !!whiteLabel
		},
		loadAccountingFirm: function () {
			return this.service
				.findAccountingFirm(this.holdingId, this.value, {
					fields: ['id', 'name', 'welcome_message', 'domain', 'deleted_at'],
					with_trashed: true
				})
				.then(accountingFirm => {
					if (accountingFirm.deleted_at) {
						accountingFirm.white_labels = []
						return accountingFirm
					}
					return this.service.findWhiteLabels(this.holdingId, accountingFirm.id).then(whiteLabels => {
						accountingFirm.white_labels = whiteLabels
						return accountingFirm
					})
				})
				.then(accountingFirm => {
					this.accountingFirm = accountingFirm
					this.accountingFirmCopy = { ...accountingFirm, white_labels: accountingFirm.white_labels.map(item => ({ ...item })) }
				})
		},
		loadContext: function () {
			this.loading = true

			if (!this.holdingId) {
				return Promise.resolve()
			}

			const promises = []
			if (this.value) {
				promises.push(this.loadAccountingFirm())
			} else {
				this.accountingFirm = {
					white_labels: BASE_WHITELABEL.map(item => {
						return { ...item }
					})
				}
				this.accountingFirmCopy = {
					white_labels: BASE_WHITELABEL.map(item => {
						return { ...item }
					})
				}
			}

			return Promise.all(promises).finally(() => {
				this.loading = false
			})
		},
		reactivateAccountingFirm: function () {
			this.reactivationLoading = true
			this.service
				.reactivateAccountingFirm(this.holdingId, this.accountingFirm.id)
				.then(accountingFirm => {
					this.appEventBus.emit(this.appEvents.ACCOUNTING_FIRM_CREATED, accountingFirm)
					this.eventBus.emit(this.events.ACCOUNTING_FIRM_REACTIVATED, accountingFirm)
					this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('workspaces.workspace_reactivated'))
					return this.loadAccountingFirm()
				})
				.finally(() => {
					this.reactivationLoading = false
				})
		},
		saveAccountingFirm: function () {
			if (this.value) {
				this.updateAccountingFirm()
			} else {
				this.createAccountingFirm()
			}
		},
		updateAccountingFirm: function () {
			this.formLoading = true
			let basePromise = Promise.resolve()
			if (this.isAccountingFirmDirty) {
				const accountingFirmData = {
					domain: this.accountingFirmCopy.domain,
					name: this.accountingFirmCopy.name,
					welcome_message: this.accountingFirmCopy.welcome_message
				}
				basePromise = this.service.modifyAccountingFirm(this.holdingId, this.accountingFirm.id, accountingFirmData)
			}

			basePromise
				.then(() => {
					const promises = []
					if (this.isWhiteLabelDirty('dark')) {
						const data = this.getDirtyWhiteLabelData('dark')
						promises.push(this.service.modifyWhiteLabel(this.holdingId, this.accountingFirm.id, 'dark', data))
					}
					if (this.isWhiteLabelDirty('light')) {
						const data = this.getDirtyWhiteLabelData('light')
						promises.push(this.service.modifyWhiteLabel(this.holdingId, this.accountingFirm.id, 'light', data))
					}

					return Promise.all(promises)
				})
				.then(() => {
					return this.loadAccountingFirm()
				})
				.then(() => {
					this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('workspaces.workspace_modified'))
					this.appEventBus.emit(this.appEvents.ACCOUNTING_FIRM_UPDATED, this.accountingFirm)
					this.eventBus.emit(this.events.ACCOUNTING_FIRM_MODIFIED, this.accountingFirm)
				})
				.finally(() => {
					this.formLoading = false
				})
		}
	}
}
</script>