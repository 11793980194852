import AbstractContextualizedModuleGuard from '@/mixins/ModulesGuards/AbstractContextualizedModuleGuard'
import Service from '@/services/Workspaces/WorkspaceService'
import { Events, Bus } from '@/events/Workspaces/WorkspaceEvents'

export default {
	mixins: [AbstractContextualizedModuleGuard],
	data: function () {
		return {
			moduleName: 'workspaces',
			eventBus: Bus,
			service: Service,
			events: Events
		}
	},
	computed: {
		hasModule: function () {
			if (!this.moduleName) {
				throw new Error("'moduleName' not defined")
			}
			const modules = this.$store.getters['modules/getHoldingModules']
            return modules.find(module => module.name === this.moduleName) != undefined
		}
	},
	mounted: function () {
		if (!this.hasModule) {
			this.$el.remove()
		}
	}
}
