import { EventBus } from '@/events/EventBus'

export const Bus = new EventBus()

export const Events = Object.freeze({
    ACCOUNTING_FIRM_ARCHIVED: 'accounting-firm-archived',
    ACCOUNTING_FIRM_CREATED: 'accounting-firm-created',
    ACCOUNTING_FIRM_DELETED: 'accounting-firm-deleted',
    ACCOUNTING_FIRM_MODIFIED: 'accounting-firm-modified',
    ACCOUNTING_FIRM_REACTIVATED: 'accounting-firm-reactivated'
})
