// ============================================================================
// WorkspaceService
// -----------------------
// Workspace module related services
// ============================================================================

// -------
// Imports
// -------
import { RequiredArguments } from '@/helpers/methods'

import API from '@/apis/WorkspaceApi'
import { store } from '@/store'

// ---------
// Internals
// ---------
const Private = {
	requests: {
        holding: {
            accountingFirm: {
                catalogTreeStructure: {
                    read: function (holdingId, accountingFirmId) {
                        RequiredArguments({ holdingId, accountingFirmId })
                        return API.holding.accountingFirm.catalogTreeStructure.read(holdingId, accountingFirmId, { show_error: false })
                    }
                },
                vendors: {
                    catalogTreeStructures: {
                        read: function (holdingId, accountingFirmId, filters = {}) {
                            RequiredArguments({ holdingId, accountingFirmId })
                            filters.show_error = false
                            return API.holding.accountingFirm.vendors.catalogTreeStructures.read(holdingId, accountingFirmId, filters)
                        }
                    }
                },
                whiteLabel: {
                    modify: function (holdingId, accountingFirmId, whiteLabelTheme, data) {
                        const formData = new FormData()
                        if (data.logo) {
                            formData.append('logo', data.logo)
                        }
                        if (data.primary_color) {
                            formData.append('primary_color', data.primary_color)
                        }
                        if (data.secondary_color) {
                            formData.append('secondary_color', data.secondary_color)
                        }

                        return API.holding.accountingFirm.whiteLabel.modify(holdingId, accountingFirmId, whiteLabelTheme, null, formData)
                    }
                },
                whiteLabels: {
                    read: function (holdingId, accountingFirmId, params) {
                        RequiredArguments({ holdingId, accountingFirmId })
                        return API.holding.accountingFirm.whiteLabels.read(holdingId, accountingFirmId, params)
                    }
                },
                create: function (holdingId, accountingFirmId, data = {}) {
                    RequiredArguments({ holdingId })
                    return API.holding.accountingFirm.create(holdingId, accountingFirmId, data)
                },
                read: function (holdingId, accountingFirmId, params) {
                    RequiredArguments({ holdingId, accountingFirmId })
                    return API.holding.accountingFirm.read(holdingId, accountingFirmId, params)
                },
                modify: function (holdingId, accountingFirmId, data = {}) {
                    RequiredArguments({ holdingId, accountingFirmId })
                    return API.holding.accountingFirm.modify(holdingId, accountingFirmId, data)
                },
                delete: function (holdingId, accountingFirmId, data) {
                    RequiredArguments({ holdingId, accountingFirmId })
                    return API.holding.accountingFirm.delete(holdingId, accountingFirmId, data)
                }
            },
            accountingFirms: {
                read: function (holdingId, params) {
                    RequiredArguments({ holdingId })
                    return API.holding.accountingFirms.read(holdingId, params)
                }
            },
            domains: {
                read: function (holdingId) {
                    RequiredArguments({ holdingId })
                    return API.holding.domains.read(holdingId)
                }
            }
        }
    },
	service: {
        holding: {
            accountingFirm: {
                create: function (holdingId, data) {
                    return Private.requests.holding.accountingFirm.create(holdingId, null, data)
                },
                reactivate: function (holdingId, accountingFirmId) {
                    RequiredArguments({ holdingId, accountingFirmId })
                    return Private.requests.holding.accountingFirm.create(holdingId, accountingFirmId, {})
                }
            },
            module: {
                exists: function (moduleName) {
                    const modules = Private.store.holding.modules.get()
                    return modules.some(({ name }) => name == moduleName)
                }
            }
        }
    },
    store: {
        holding: {
            modules: {
                get: function () {
                    return store.getters['modules/getHoldingModules']
                }
            }
        }
    }
}

// -------
// Exports
// -------
export default {
    createAccountingFirm: Private.service.holding.accountingFirm.create,
    findAccountingFirm: Private.requests.holding.accountingFirm.read,
    listAccountingFirms: Private.requests.holding.accountingFirms.read,
    modifyAccountingFirm: Private.requests.holding.accountingFirm.modify,
    archiveAccountingFirm: Private.requests.holding.accountingFirm.delete,
    reactivateAccountingFirm: Private.service.holding.accountingFirm.reactivate,
    deleteAccountingFirm: Private.requests.holding.accountingFirm.delete,

    findAccountingFirmCatalogTreeStructure: Private.requests.holding.accountingFirm.catalogTreeStructure.read,
    listCatalogTreeStructures: Private.requests.holding.accountingFirm.vendors.catalogTreeStructures.read,

    listDomains: Private.requests.holding.domains.read,

    hasModule: Private.service.holding.module.exists,

    findWhiteLabels: Private.requests.holding.accountingFirm.whiteLabels.read,
    modifyWhiteLabel: Private.requests.holding.accountingFirm.whiteLabel.modify
}
